module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-analytics-without-segment/gatsby-browser.js'),
      options: {"plugins":[],"cdnUrl":"https://cdnjs.cloudflare.com/ajax/libs/analytics.js/2.9.1/analytics.min.js","services":{"Google Analytics":{"trackingId":"UA-73665-24","anonymizeIp":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WRX25JL","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    }]
